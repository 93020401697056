import { IPhoneNumber } from "../types/interfaces";

type FileTypes =
    | ".png"
    | ".jpg"
    | ".jpeg"
    | ".heic"
    | ".pdf"
    | "image/png"
    | "image/jpeg"
    | "application/pdf";

const getPhoneNumberList = (phoneList: any): string[] => {
    const phonesArr: string[] | null = [];
    phoneList?.forEach((item: IPhoneNumber) => {
        if (item.phoneNumber != null) {
            phonesArr.push(item.phoneNumber);
        }
    });
    return phonesArr;
};

export { getPhoneNumberList }
export type { FileTypes }
