import React, { ChangeEvent, MouseEvent } from "react";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import { ReactComponent as Plus } from "../../assets/newImg/personalAccount/plus.svg";
import attachedDoc from "../../assets/newImg/personalAccount/attached_document.svg";
import delete_document from "../../assets/newImg/personalAccount/delete_document.svg";
import rejectedDoc from "../../assets/newImg/personalAccount/rejected_document.svg";
import approvedDoc from "../../assets/img/approved.png";
import type { FileTypes } from "../../Constants/KycConstants";

type FileInputProps = {
    s: any;
    documents: any;
    inputId: string;
    allowedTypes: FileTypes[];
    identifier: string;
    maxFileSize: number;
    kycList?: any;
    documentTitle?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onImageClick?: (event: MouseEvent<HTMLImageElement>) => void;
};

const FileInput = (props: FileInputProps) => {
    const {
        s,
        inputId,
        allowedTypes,
        documents,
        identifier,
        maxFileSize,
        onChange,
        onImageClick,
    } = props;

    const allowedTypesString = allowedTypes.join(", ");

    const maxSize = maxFileSize * 1024 * 1024;

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            if (file.size > maxSize) {
                alert(
                    "The file size exceeds the " +
                        maxFileSize +
                        "MB limit. Please upload a smaller file."
                );
                event.target.value = "";
                return;
            }

            if (onChange) {
                onChange(event);
            }
        }
    };

    return (
        <div className={s.attached_file_wrapper}>
            <div className={s.btn_block}>
                <TransparentButton
                    icon={<Icon component={() => <Plus />} rev={undefined} />}
                    title={"Add file"}
                    onClick={(e: any) => {
                        e.preventDefault();
                        // @ts-ignore
                        document.getElementById(inputId).click();
                    }}
                />
                <input
                    type="file"
                    accept={allowedTypesString}
                    id={inputId}
                    className={s.hidden_input}
                    onChange={handleFileChange}
                />
            </div>

            <div className={s.attached_file}>
                {props.kycList &&
                    props.kycList[0]?.files?.map((item: any, index: number) => {
                        if (
                            item.fileType !== identifier ||
                            documents[identifier]
                        )
                            return null;

                        if (item.status === "Rejected") {
                            return (
                                <React.Fragment key={index}>
                                    <img src={rejectedDoc} alt="" />
                                    <span className={s.rejected_doc_name}>
                                        {item.fileType}&nbsp;<b>-&nbsp;[Rejected]</b>
                                    </span>
                                </React.Fragment>
                            );
                        }

                        if (item.status === "Confirmed") {
                            return (
                                <React.Fragment key={index}>
                                    <img src={approvedDoc} alt="" />
                                    <span
                                        className={s.approved_doc_name}
                                    >
                                        {item.fileType}&nbsp;<b>-&nbsp;[Approved]</b>
                                    </span>
                                </React.Fragment>
                            );
                        }

                        return null;
                    })}

                {documents[identifier]?.name && (
                    <>
                        <img src={attachedDoc} alt="" />
                        <span className={s.attached_file_name}>
                            {documents[identifier].name}
                        </span>
                        <img
                            onClick={onImageClick}
                            className={s.delete_doc}
                            src={delete_document}
                            alt=""
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default FileInput;
